<template>
  <b-navbar
    id="navbar"
    toggleable="lg"
    type="dark"
    class="bg-custom"
    fixed="top"
  >

    <router-link
      :to="{ name: 'home' }"
      @click.native="isActive = false"
    >
      <b-navbar-brand id="navbar-brand">Bittles Bar.</b-navbar-brand>
    </router-link>

    <b-navbar-toggle
      target="nav-collapse"
      class="custom-toggler"
      :class="{change: isActive}"
    >
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
      v-model="isActive"
    >
      <b-navbar-nav class="ml-auto">
        <router-link
          :to="{ name: 'home' }"
          active-class="active"
          tag="b-nav-item"
          exact
          @click.native="isActive = false"
        >
          <span class="custom-nav-item">Home</span>
        </router-link>
        <!-- <router-link
          :to="{ name: 'about' }"
          active-class="active"
          tag="b-nav-item"
          @click.native="isActive = false"
        >
          <span class="custom-nav-item">About</span>
        </router-link>
        <router-link
          :to="{ name: 'reviews' }"
          active-class="active"
          tag="b-nav-item"
          @click.native="isActive = false"
        >
          <span class="custom-nav-item">Reviews</span>
        </router-link>
        <router-link
          :to="{ name: 'faq' }"
          active-class="active"
          tag="b-nav-item"
          @click.native="isActive = false"
        >
          <span class="custom-nav-item">FAQ</span>
        </router-link> -->
        <router-link
          :to="{ name: 'contact' }"
          active-class="active"
          tag="b-nav-item"
          @click.native="isActive = false"
        >
          <span class="custom-nav-item">Contact</span>
        </router-link>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style scoped>
/*******************
 *  Nav Bar Style  *
 *******************/
.bg-custom {
  background-color: #1b5232;
}

#navbar {
  box-shadow: 0 2px 20px -1px rgba(0, 0, 0, 0.753);

  display: flex;
  align-items: flex-end;

  padding-bottom: 5px;
}

/****************
 *  Brand Name  *
 ****************/
#navbar-brand {
  color: #f2ad32;
  font-family: "Abhaya Libre", serif;
  text-shadow: 0px 4px 7px rgba(39, 39, 39, 0.781);
  margin-left: 5px;
  margin-top: 25px;
  font-size: 1.875rem;
  padding: 0;
}

#navbar-brand:visited {
  color: #f2ad32;
}

/********************
 *  Hamburger Icon  *
 ********************/
.custom-toggler.navbar-toggler {
  border-color: #ffffff00;
  margin-top: auto;
  margin-bottom: auto;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 4px;
  background-color: #133722;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 20px;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

/***************
 *  Nav Links  *
 ***************/
.nav-link {
  font-family: "Yaldevi", sans-serif;
  font-weight: 700;
  transition: all 500ms ease;
}

ul.navbar-nav li a .custom-nav-item {
  color: #0d2918;
}

ul.navbar-nav li a .custom-nav-item:hover {
  color: #f2ad32;
  transition: all 500ms ease;
}

ul.navbar-nav li.active a .custom-nav-item {
  color: #f2ad32;
  transition: all 500ms ease;
}

.custom-nav-item {
  font-family: "Yaldevi", sans-serif;
  font-weight: 700;
  transition: all 500ms ease;
}

/*************************
 *  Collapsed Nav Links  *
 *************************/
.navbar-collapse.collapsing,
.navbar-collapse.show {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>