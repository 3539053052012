export const state = {
  contactList: [
    {
      title: "Address",
      description:
        "70 Upper Church Lane<br>Belfast<br>BT1 4QL<br>County Antrim",
      icons: [
        "M246.181,0C127.095,0,59.533,102.676,84.72,211.82c17.938,77.722,126.259,280.631,161.462,280.631c32.892,0,143.243-202.975,161.463-280.631C432.996,103.74,365.965,0,246.181,0z M246.232,224.97c-34.38,0-62.244-27.863-62.244-62.244c0-34.381,27.864-62.244,62.244-62.244c34.38,0,62.244,27.863,62.244,62.244C308.476,197.107,280.612,224.97,246.232,224.97z",
      ],
      viewBox: "0 0 492.452 492.452",
      background: "backgroundColor: rgba(59, 85, 70, 0.99);",
      sizeIncrease: false,
    },
    {
      title: "Social",
      description:
        '<strong>Facebook:</strong><br> <a href="https://www.facebook.com/BittlesBar" target="_blank">facebook.com/BittlesBar</a><br><br><strong>Instagram:</strong><br> <a href="https://www.instagram.com/bittlesbar" target="_blank">instagram.com/BittlesBar</a>',
      icons: [
        "M206.093,181.516c17.379,0,31.515-14.138,31.515-31.515V54.593c0-17.378-14.136-31.518-31.515-31.518H31.51C14.135,23.075,0,37.215,0,54.593v95.408c0,17.377,14.135,31.515,31.51,31.515h22.933v32.988c0,4.012,2.416,7.627,6.121,9.162c1.228,0.507,2.516,0.753,3.792,0.753c2.58,0,5.117-1.007,7.014-2.904l39.991-39.999H206.093z",
        "M269.415,105.583h-15.944v44.418c0,26.125-21.253,47.378-47.378,47.378h-88.161l-18.537,18.542c2.234,12.987,13.567,22.902,27.181,22.902h76.762l32.191,32.196c1.896,1.897,4.434,2.905,7.014,2.905c1.276,0,2.564-0.249,3.792-0.756c3.705-1.535,6.12-5.149,6.12-9.16v-25.186h16.961c15.211,0,27.585-12.378,27.585-27.591v-78.061C297,117.959,284.626,105.583,269.415,105.583z",
      ],
      viewBox: "0 0 297 297",
      background: "backgroundColor: rgba(91, 76, 65, 0.99);",
      sizeIncrease: true,
    },
    {
      title: "Hours",
      description:
        "<strong>Mon:</strong> 11:30am-11pm<br><strong>Tue:</strong> 11:30am-11pm<br><strong>Wed:</strong> 11:30am-11pm<br><strong>Thu:</strong> 11:30am-12am<br><strong>Fri:</strong> 11:30am-1am<br><strong>Sat:</strong> 11:30am-1am<br><strong>Sun:</strong> 11:30am-11pm",
      icons: [
        "M233.004,0C104.224,0,0,104.212,0,233.004c0,128.781,104.212,233.004,233.004,233.004c128.782,0,233.004-104.212,233.004-233.004C466.008,104.222,361.796,0,233.004,0z M244.484,242.659l-63.512,75.511c-5.333,6.34-14.797,7.156-21.135,1.824c-6.34-5.333-7.157-14.795-1.824-21.135l59.991-71.325V58.028c0-8.284,6.716-15,15-15s15,6.716,15,15v174.976h0C248.004,236.536,246.757,239.956,244.484,242.659z",
      ],
      viewBox: "0 0 466.008 466.008",
      background: "backgroundColor: rgba(214, 175, 103, 0.98);",
      sizeIncrease: false,
    },
  ],
};

export const getters = {
  contactList(state) {
    return state.contactList;
  },
};
