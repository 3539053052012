<template>
  <b-col
    style="padding: 0"
    :style="item.background"
    xl="4"
    lg="4"
    md="4"
    sm="12"
  >
    <div
      class="card-container"
      :class="{ 'size-increase': item.sizeIncrease }"
    >
      <p class="title">{{ item.title }}</p>
      <div class="info-card">
        <p
          v-html="item.description"
          class="description"
        ></p>
        <div
          class="icon-container"
          :class="{ 'high-icon-container': !item.sizeIncrease }"
        >
          <svg
            class="icon"
            :viewBox="item.viewBox"
          >
            <path
              v-for="(icon, index) in item.icons"
              fill="#ffffff"
              :d="icon"
              :key="index"
            />
          </svg>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style scoped>
.card-container {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 20px;
  width: 78%;
  min-height: 300px;
  margin: 85px auto 55px auto;
}

.size-increase {
  width: 82%;
  min-height: 370px;
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.397) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.info-card {
  border-radius: 0px 0px 20px 20px;
  min-height: inherit;
  background: white;
  background: linear-gradient(315deg, #ffffff 0%, #f6f2f2 74%);
}

.icon {
  width: 2.5rem;
  fill: white;
}

.high-icon-container {
  bottom: 11% !important;
}

.icon-container {
  padding: 0.9rem;
  border-radius: 50px;
  background: #c8902c;
  background: -webkit-linear-gradient(bottom right, #ffc255, #c8902c);
  background: -moz-linear-gradient(bottom right, #ffc255, #c8902c);
  background: linear-gradient(to top left, #ffc255, #c8902c);
  display: inline-block;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translate(-50%, 0);
}

.title {
  color: white;
  font-size: 1.2rem;
  font-family: "Yaldevi", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background: #c8902c;
  background: -webkit-linear-gradient(bottom right, #ffc255, #c8902c);
  background: -moz-linear-gradient(bottom right, #ffc255, #c8902c);
  background: linear-gradient(to top left, #ffc255, #c8902c);
  padding: 15px 25px 10px 25px;
  margin-bottom: 0;
  border-radius: 20px 20px 0 0;
  text-align: center;
  margin: 0px auto 0px auto;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
}

.description {
  margin-bottom: 30px;
  font-family: "Yaldevi", sans-serif;
  font-weight: 400;
  font-size: 1.35rem;
  letter-spacing: 0.03em;
  padding: 20px 20px 40px 20px;
}

/*************
 *  Queries  *
 *************/
@media only screen and (max-width: 767px) {
  .high-icon-container {
    bottom: 6% !important;
  }

  .card-container {
    width: 85%;
    min-height: 330px;
    margin: 50px auto 55px auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}

@media only screen and (max-width: 1044px) {
  .description {
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 767px) {
  .description {
    font-size: 1.5rem;
  }
}
</style>