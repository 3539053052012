<template>
  <div>
    <HomeCarousel />
    <b-container fluid>
      <b-row class="home-row">
        <HomeColumn
          v-for="(item, index) in infoList"
          :key="index"
          :item="item"
        />
      </b-row>
    </b-container>

    <b-embed
      type="iframe"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2311.303365126625!2d-5.9264675841225944!3d54.59864818026047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48610855afa27d77%3A0xe2b44dd9f8d4e286!2sBittles%20Bar!5e0!3m2!1sen!2suk!4v1642325840554!5m2!1sen!2suk"
      allowfullscreen
    ></b-embed>
  </div>
</template>

<script>
import Carousel from "../components/pages/home/Carousel.vue";
import Column from "../components/pages/home/HomeColumn.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HomeCarousel: Carousel,
    HomeColumn: Column,
  },
  computed: {
    ...mapGetters(["infoList"]),
  },
  methods: {
    ...mapActions(["transitionOff"]),
  },
  beforeRouteLeave(to, from, next) {
    this.transitionOff();
    next();
  },
};
</script>

<style scoped>
h1 {
  border: 1px solid black;
}

.embed-responsive-16by9 {
  height: 85vh;
}

.home-row {
  background-color: #ffffff;
  opacity: 1;
  background: repeating-linear-gradient(
    -45deg,
    #000000,
    #000000 7px,
    #ffffff 7px,
    #ffffff 35px
  );
}
</style>