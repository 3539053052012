<template>
  <div>
    <div class="white-space"></div>
    <b-container
      fluid
      class="contact-container"
    >
      <b-row class="form-row">
        <FormColumn />
      </b-row>
      <b-row class="info-row">
        <ContactColumn
          v-for="(item, index) in contactList"
          :key="index"
          :item="item"
        />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FormColumn from "../components/pages/contact/FormColumn.vue";
import ContactColumn from "../components/pages/contact/ContactColumn.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FormColumn,
    ContactColumn,
  },
  computed: {
    ...mapGetters(["contactList"]),
  },
};
</script>

<style scoped>
.form-row,
.info-row {
  background-image: url("../assets/images/contactPage.jpg");
  background-size: cover;
  width: 100%;
  margin: 0;
}

.info-row {
  background-color: #ffffff;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.575);
  opacity: 1;
  background: repeating-linear-gradient(
    -45deg,
    #000000,
    #000000 7px,
    #ffffff 7px,
    #ffffff 35px
  );
}

.form-row {
  background-color: rgba(138, 135, 112, 0.99);
}

.contact-container {
  padding: 0;
}
</style>