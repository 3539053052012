import Vue from "vue";
import Vuex from "vuex";
import * as homeCard from "./modules/homeCard";
import * as contactCard from "./modules/contactCard";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    homeCard,
    contactCard,
  },
  state: {
    transition: true,
  },
  mutations: {
    TRANSITION_OFF(state) {
      state.transition = false;
    },
  },
  actions: {
    transitionOff({ commit }) {
      commit("TRANSITION_OFF");
    },
  },
  getters: {
    getTransition: (state) => {
      return state.transition;
    },
  },
});
