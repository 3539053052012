export const state = {
  infoList: [
    {
      title: "Central Location.",
      description:
        "Located in the heart of Belfast, our venue is within walking distance of the top attractions in the city.",
      image: "homeCol1.jpg",
      icons: [
        "m7 14a1 1 0 0 1 -1 1h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 1 1zm4-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm-5 4h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm5 0h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm-5-12h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm5 0h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm-5 4h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm5 0h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm13 1v9a5.006 5.006 0 0 1 -5 5h-14a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h6a5.006 5.006 0 0 1 5 5h3a5.006 5.006 0 0 1 5 5zm-19 12h9v-17a3 3 0 0 0 -3-3h-6a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3zm17-12a3 3 0 0 0 -3-3h-3v15h3a3 3 0 0 0 3-3zm-3 3a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm0 4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm0-8a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z",
      ],
      background: "backgroundColor: rgba(59, 85, 70, 0.99);",
    },
    {
      title: "Gorgeous Pints.",
      description:
        "We're known for our freshly squeezed pints! You wont find a better pint of Guinness in all of Belfast.",
      image: "homeCol2.jpg",
      icons: [
        "M19.5,9H19V7a1,1,0,0,0-1-1,3.9,3.9,0,0,0-2.016-3.485,4.093,4.093,0,0,0-2.359-.5.593.593,0,0,1-.54-.223,4.509,4.509,0,0,0-7.332.229A3.993,3.993,0,0,0,2,6,1,1,0,0,0,1,7V19a5.006,5.006,0,0,0,5,5h8a5.006,5.006,0,0,0,5-5h.5A3.5,3.5,0,0,0,23,15.5v-3A3.5,3.5,0,0,0,19.5,9ZM6,4a.963.963,0,0,1,.171.021,1,1,0,0,0,1.068-.567A2.5,2.5,0,0,1,11.486,3a2.576,2.576,0,0,0,2.332,1.012,2.157,2.157,0,0,1,1.228.271A1.938,1.938,0,0,1,16,6H12A3,3,0,0,0,9,9v3a1,1,0,0,1-2,0V9A3,3,0,0,0,4,6,2,2,0,0,1,6,4ZM17,19a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V8H4A1,1,0,0,1,5,9v3a3,3,0,0,0,6,0V9a1,1,0,0,1,1-1h5Zm4-3.5A1.5,1.5,0,0,1,19.5,17H19V11h.5A1.5,1.5,0,0,1,21,12.5Z",
      ],
      background: "backgroundColor: rgba(91, 76, 65, 0.99)",
    },
    {
      title: "Premium Whiskeys.",
      description:
        "With hundreds of whiskeys to choose from, you're guaranteed to find the right one for you.",
      image: "homeCol3.jpg",
      icons: [
        "M23.955,21.319a1,1,0,0,0-1.252-.658l-1.3.4-.931-2.737A4.953,4.953,0,0,0,22.8,12.607L20.957,7.132A3.023,3.023,0,0,0,17.28,5.11L13.428,6.184A2.966,2.966,0,0,0,12,7.078a2.966,2.966,0,0,0-1.428-.894L6.719,5.11A3.008,3.008,0,0,0,3.052,7.1L1.185,12.657a4.956,4.956,0,0,0,2.364,5.682l-.944,2.728L1.3,20.661A1,1,0,1,0,.7,22.571l2.16.671c.015,0,.024.017.039.023s.052.006.078.013l2.181.677a1,1,0,0,0,.594-1.91L4.516,21.66l.935-2.7A4.982,4.982,0,0,0,6.01,19,5,5,0,0,0,10.8,15.392L12,11.836l1.183,3.5A5,5,0,0,0,17.99,19a4.917,4.917,0,0,0,.581-.04l.918,2.7-1.244.386a1,1,0,0,0,.594,1.91l2.175-.675c.026-.007.053,0,.079-.013s.026-.019.042-.025l2.162-.671A1,1,0,0,0,23.955,21.319ZM13.357,8.592a.989.989,0,0,1,.608-.482l3.851-1.073A1,1,0,0,1,18.08,7a1.023,1.023,0,0,1,.972.739L19.813,10H13.49l-.215-.637A1,1,0,0,1,13.357,8.592ZM5.918,7a1.007,1.007,0,0,1,.265.036L10.035,8.11a1,1,0,0,1,.7,1.223L10.51,10H4.187l.77-2.29A1.008,1.008,0,0,1,5.918,7Zm2.974,7.8a3,3,0,0,1-5.5.669A2.949,2.949,0,0,1,3.1,13.245L3.514,12H9.836Zm7.632,1.808a3.022,3.022,0,0,1-1.431-1.859L14.164,12h6.323l.4,1.194a3,3,0,0,1-4.366,3.418Z",
        "M15.553,3.9A1,1,0,0,0,16.9,3.447l1-2a1,1,0,1,0-1.79-.894l-1,2A1,1,0,0,0,15.553,3.9Z",
        "M7.105,3.447A1,1,0,1,0,8.9,2.553l-1-2a1,1,0,0,0-1.79.894Z",
        "M12,4a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V3A1,1,0,0,0,12,4Z",
      ],
      background: "backgroundColor: rgba(214, 175, 103, 0.98)",
    },
    {
      title: "Historic Building.",
      description:
        "Known as the Flat Iron Building, this iconic location has been a landmark in Belfast for centuries.",
      image: "homeCol4.jpg",
      icons: [
        "m24 23a1 1 0 0 1 -1 1h-22a1 1 0 0 1 0-2h22a1 1 0 0 1 1 1zm-23.709-14.448a2.443 2.443 0 0 1 .153-2.566 4.716 4.716 0 0 1 1.668-1.5l7.501-3.904a5.174 5.174 0 0 1 4.774 0l7.5 3.907a4.716 4.716 0 0 1 1.668 1.5 2.443 2.443 0 0 1 .153 2.566 2.713 2.713 0 0 1 -2.416 1.445h-.292v8h1a1 1 0 0 1 0 2h-20a1 1 0 0 1 0-2h1v-8h-.292a2.713 2.713 0 0 1 -2.417-1.448zm4.709 9.448h3v-8h-3zm5-8v8h4v-8zm9 0h-3v8h3zm-16.937-2.375a.717.717 0 0 0 .645.375h18.584a.717.717 0 0 0 .645-.375.452.452 0 0 0 -.024-.5 2.7 2.7 0 0 0 -.949-.864l-7.5-3.907a3.176 3.176 0 0 0 -2.926 0l-7.5 3.907a2.712 2.712 0 0 0 -.949.865.452.452 0 0 0 -.026.499z",
      ],
      background: "backgroundColor: rgba(138, 135, 112, 0.99)",
    },
  ],
};

export const getters = {
  infoList(state) {
    return state.infoList;
  },
};
