<template>
  <div>
    <div class="white-space"></div>
    <AboutCarousel />
  </div>
</template>

<script>
import Carousel from "../components/pages/about/Carousel.vue";

export default {
  components: {
    AboutCarousel: Carousel,
  },
};
</script>

<style scoped>
h1 {
  border: 1px solid black;
}
</style>