<template>
  <b-carousel
    id="carousel-1"
    v-model="slide"
    :interval="5000"
    controls
    indicators
    background="#ababab"
    img-width="2400"
    img-height="1098"
    style="text-shadow: 1px 1px 2px #333;"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <b-carousel-slide class="myClass">
      <template #img>
        <img
          id="slide1"
          class="d-block w-100"
          src="../../../assets/images/about1.jpg"
          alt="image slot"
        >
      </template>
    </b-carousel-slide>

    <b-carousel-slide class="myClass">
      <template #img>
        <img
          id="slide2"
          class="d-block w-100"
          src="../../../assets/images/bittles2.jpg"
          alt="image slot"
        >
      </template>
    </b-carousel-slide>
    <p class="title">Based in the heart of Belfast for {{ years }} years</p>
  </b-carousel>
</template>

<script>
export default {
  computed: {
    years() {
      var year = new Date().getFullYear();
      return year - 1990;
    },
  },
};
</script>

<style scoped>
#slide1,
#slide2,
#slide3 {
  object-fit: cover;
  height: 90vh;
}

#carousel-1 {
  position: relative;
}

.title {
  position: absolute;
  border: 2px solid pink;
  top: 22.5%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 4rem;
  text-align: center;
  width: 40%;
  text-transform: uppercase;
  font-family: "Yaldevi", sans-serif;
  font-weight: 600;
  text-shadow: 0px 4px 20px rgb(0, 0, 0);
}

@media (min-aspect-ratio: 864/532) {
  .title {
    display: none;
  }
}

@media only screen and (max-width: 967px) {
  .title {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 846px) {
  .title {
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 773px) {
  .title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 725px) {
  .title {
    font-size: 2.7rem;
  }
}

@media only screen and (max-width: 652px) {
  .title {
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 580px) {
  .title {
    font-size: 3rem;
    width: 80%;
    top: 20%;
  }
}

/* @media only screen and (max-width: 483px) {
  .title {
    font-size: 1.7rem;
  }
} */
</style>