<template>
  <div>
    <app-header />
    <router-view></router-view>
    <app-footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
  },
};
</script>

<style>
.white-space {
  height: 83px;
}

html {
  background-color: #1b5232;
}

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&family=Yaldevi:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
</style>

