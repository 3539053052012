<template>
  <div class="carousel-wrapper">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="5000"
      controls
      indicators
      background="#ababab"
      img-width="2400"
      img-height="1098"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide class="myClass">
        <template #img>
          <img
            id="slide1"
            class="d-block w-100"
            src="../../../assets/images/edited/one.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>

      <b-carousel-slide class="myClass">
        <template #img>
          <img
            id="slide2"
            class="d-block w-100"
            src="../../../assets/images/bittles2.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>

      <b-carousel-slide class="myClass">
        <template #img>
          <img
            id="slide3"
            class="d-block w-100"
            src="../../../assets/images/edited/three.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
    <!-- <transition
      v-if="getTransition"
      name="view"
      appear
    >
      <img
        class="logo"
        src="../../../assets/images/logo.png"
        alt=""
      >
    </transition>
    <img
      v-else
      class="logo"
      src="../../../assets/images/logo.png"
      alt=""
    > -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  computed: {
    ...mapGetters(["getTransition"]),
  },
};
</script>

<style scoped>
#slide1,
#slide2,
#slide3 {
  object-fit: cover;
  height: 100vh;
}

.carousel-wrapper {
  position: relative;
}

.logo {
  position: absolute;
  width: 45%;
  min-width: 350px;
  top: 22.5%;
  left: 50%;
  transform: translate(-50%, 0);
}

.view-leave-active {
  transition: opacity 2s ease-in-out, transform 2s ease;
}

.view-enter-active {
  transition: opacity 2s ease-in-out, transform 2s ease;
  transition-delay: 1.5s;
}

.view-enter,
.view-leave-to {
  opacity: 0;
}

.view-enter-to,
.view-leave {
  opacity: 1;
}
</style>
