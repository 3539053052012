// Set global min wifth, like teamwork chat
// In carousel - Put logo and tag line inside one div
// Change px measurements to rem or vh
// Make animations for hamburger button and main logo on home screen
// Consider contrast of colours in nav bar
// Add animation for navigating between pages
// Check responsiveness for each device on chrome tools
// Remove all old and commented code
// Test media quries in Carousel.vue, are they robust?
// Edit home carousel logo photo, remove top and bottom details and try to make black background more transparent.
// Form validation
// Optimise images and files for website speed. fast loading speed.
// v-if scenario for if a form has already been submitted. user will be told form submitted and button to go again will show form again?
// Fix min/max width of form
// Split contact page into components
// split the cards into different rows depending on screen size
// consider removing map from contact us page
// Get main heading "Get In Touch" on contact page to belong to form card rather than the overlay
// Remove "viewport" and other attributes from svg element if they arent neccessary
// Organise styles and refactor!
// Translating the form to overlap the map will break when the height is reduced, find new solution, maybe fixed height for map or something
// Sanitize the HTML string which is on the contact page info cards

import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import BootstrapVue from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
