<template>
  <b-col
    :style="item.background"
    sm="12"
    md="6"
    lg="3"
  >
    <div class="icon-image-container">
      <p class="title">{{ item.title }}</p>
      <img
        class="image"
        :src="require('../../../assets/images/' + item.image)"
        alt="pints"
      >
      <div class="icon-container">
        <svg
          class="info_icon"
          viewBox="0 0 24 24"
        >
          <path
            v-for="(icon, index) in item.icons"
            fill="#ffffff"
            :d="icon"
            :key="index"
          />
        </svg>
      </div>
      <p class="description">{{ item.description }}</p>
    </div>
  </b-col>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style scoped>
.info_icon {
  width: 2.5rem;
  color: white;
}

.title {
  color: white;
  font-size: 1rem;
  font-family: "Yaldevi", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background: #c8902c;
  background: -webkit-linear-gradient(bottom right, #ffc255, #c8902c);
  background: -moz-linear-gradient(bottom right, #ffc255, #c8902c);
  background: linear-gradient(to top left, #ffc255, #c8902c);
  padding: 22px 25px 17px 25px;
  margin-bottom: 0;
  border-radius: 20px 20px 0 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
}

.image {
  display: block;
  width: 100%;
}

.icon-image-container {
  position: relative;
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 20px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.icon-container {
  padding: 0.9rem;
  border-radius: 50px;
  background: #c8902c;
  background: -webkit-linear-gradient(bottom right, #ffc255, #c8902c);
  background: -moz-linear-gradient(bottom right, #ffc255, #c8902c);
  background: linear-gradient(to top left, #ffc255, #c8902c);
  display: inline-block;
  position: absolute;
  bottom: -3%;
  right: -5%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.description {
  margin-bottom: 30px;
  font-family: "Yaldevi", sans-serif;
  font-weight: 300;
  font-size: 1.063rem;
  letter-spacing: 0.03em;
  background: white;
  background: linear-gradient(315deg, #ffffff 0%, #f6f2f2 74%);
  border-radius: 0 0 20px 20px;
  padding: 20px 20px 40px 20px;
  min-height: 225px;
}

/************************
 *  Card Title Queries  *
 ************************/
@media only screen and (max-width: 1042px) {
  .title {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 991px) {
  .title {
    font-size: 1.07rem;
  }
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 1.2rem;
  }
}

/******************************
 *  Card Description Queries  *
 ******************************/
@media only screen and (max-width: 1147px) {
  .description {
    font-size: 1.023rem;
  }
}

@media only screen and (max-width: 1064px) {
  .description {
    font-size: 1.01rem;
  }
}

@media only screen and (max-width: 1002px) {
  .description {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 991px) {
  .description {
    font-size: 1.3rem;
  }
}
</style>